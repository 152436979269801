/* ========================================================================
 * Handles slider functionality on the homepage
 * ======================================================================== */

(function($) {
	$(document).ready(function(){

		var slideshow_container_class = '.home-slideshow-container';

		// Check if a slideshow container is on the page
		if ( $( slideshow_container_class ) ) {
			
			// Set main variables/values
			var slideshow_container = $( slideshow_container_class ),
				number_of_slides = $( slideshow_container_class+' li' ).length,
				active_slide = 0,
				viewportWidth = $("body").innerWidth(),
				animationLength = 1600,
				slide_delay = 6000,
				play_slides = true;

			// Ensure viewPortWidth is maintained
			$( window ).resize( function() {
				viewportWidth = $("body").innerWidth();
			});

			// Initial setup
			$( slideshow_container_class+' li' ).css( {'z-index':'0', 'left':'-'+(viewportWidth+17)+'px'} );
			$( slideshow_container_class+' ul li:first-of-type' ).css( {'z-index':'1', 'left':'0px'} ); 

			// Function for handling automatic slideshow
			var nextSlide = function() {

				// If play slides is not equal to true, the slideshow is paused
				if ( play_slides === true ) {

					// Get current slide
					var current_slide = $( slideshow_container_class+' li' ).eq(active_slide);
					
					// Select next slide
					if ( (active_slide + 1) >= number_of_slides ) {
						active_slide = 0;
					} else {
						active_slide++;
					}
					next_slide = $( slideshow_container_class+' li' ).eq(active_slide);

					// Animate current slide
					current_slide.animate({
						// Slide to the right by viewport width
						left: viewportWidth+'px',
					}, animationLength, function() {
						// Ensure position is reset to left side after animation
						current_slide.css( {'z-index':'0', 'left':'-'+(viewportWidth+17)+'px'} );
					});

					// Animate next slide
					next_slide.animate({
						// Slide into viewport
						left: '0',
					}, animationLength, function() {
						// Ensure is on top after animation
						next_slide.css( 'z-index', '1' );
					});
				}
			};

			// Only execute if more than one slide is present
			if ( number_of_slides > 1 ) {
				setInterval( nextSlide, slide_delay );
			}
		}
	});
})(jQuery);