/**
  * @summary Archive Scroll
  *
  * On click, scroll to next article listings.
  *
  * @since 1.1.0
  * @since 1.2.0    Changed function declarations to function expressions.
  */
( function( $ ) {
    $(document).ready( function() {
        var $document = $( document ),
            $element = $( '.site-header' ),
            className = 'header-background';

        $document.scroll( function() {
          if ( $document.scrollTop() >=  1) {
            $element.addClass( className );
          } else {
            $element.removeClass( className );
          }
        });
    });
} )( jQuery );