/* ========================================================================
 * Handles custom functionality revolving around the appointment form
 * ======================================================================== */
(function($){
	var targets = '.appointment-form, .page-content';

	$(window).on('hashchange', function () {
		
		if (window.location.hash === '#header') {
			$('#first-name').focus();
		}
	});


// Smooth Scroll All Internal Links

     // Find all internal/null links and listen for clicks on them
      $('a[href*="#"]').not('.dropdown-toggle-anchor').not('.fully-padded').click(function(event) {
        //Apply function only to internal links
        if ((event.currentTarget.hash.length > 1) || (event.currentTarget.hash === window.location.hash)) {
          //When internal links are clicked, scroll the window smoothly down (or up) to the elements they target.
          // event.preventDefault();

          var currentTarget = $(this.hash);

          $('html, body').animate({
            scrollTop : $(currentTarget).offset().top - 210
          }, 1000);

          window.setTimeout(function () {
            window.location.hash = currentTarget[0].id;
          }, 100);

          return false;
        }
      });
})(jQuery);
