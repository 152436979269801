/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  (function ($) {
    $('.major-navigation a[href*="condition"]').parent();
  })(jQuery);

  (function($) {
    $('.condition-footer-shareArticle').removeClass('hide-temp');
  })(jQuery);

  (function($) {
    var index,
      justSearched;

    function filterSearchResults (event) {
       var val = $('#input-problem').val();

        var targetOption = $('#problemsList option').filter(function() {
            return this.value.toLowerCase() === val.toLowerCase();
        });

        if ($('#problemsList').css('display') !== 'none') {
          if ($('#problemsListFallback option').filter(':selected').length > 0) {
            targetOption = $('#problemsListFallback option').filter(':selected');
           }
        }

        if (targetOption.length) {
          var ids = targetOption.attr('data-associatedConditions').split(' ');

          if (ids.length) {
            // $('.conditionsArchive').fadeOut();
            for (index = 0; index < ids.length; index++) {
              var currentString = ids[index];
              currentIDQuery = '#' + currentString;
              $(currentIDQuery).addClass('matches-problem');
            }

            $('.conditionsArchive-condition').not('.matches-problem').hide();
            $('.conditions-live-search').addClass('completed');
            $('.conditionsArchive').addClass('displaying-results');
            // $('.conditionsArchive').fadeIn();
            // $('#input-problem').blur();
          }
        }

        return targetOption;
    }

    function clearSearch () {
      $('.conditions-live-search').removeClass('completed');
      $('.conditions-live-search').removeClass('searching');
      $('.conditionsArchive').removeClass('searching');
      $('.matches-problem').removeClass('matches-problem');
      $('.conditionsArchive').removeClass('displaying-results');
      $('.conditionsArchive-condition').fadeIn();
    }

    $('input#input-problem').click();

    $('#input-problem').on('blur', function() {
      $('.conditions-live-search').removeClass('searching');
      $('.conditionsArchive').removeClass('searching');
    });

    $('#input-problem').on('focus', function() {
      if($(this).val().length < 1) {
        clearSearch();
      } else {
        $('.conditionsArchive').addClass('searching');
      }
    });

      $('#conditions-live-search-form').on('submit', function (event) {
        event.preventDefault();
        clearSearch();
        filterSearchResults(event);
      });

    $('#clear-search').on('click', function (event) {
        // event.preventDefault();
        clearSearch();
    });

    $('#input-problem').on('input', function() {
      if($(this).val().length < 1) {
        clearSearch();
      } else {
        $('.conditions-live-search').addClass('searching');
        $('.conditionsArchive').addClass('searching');
      }
    });

    if(!($('.conditionsArchive').hasClass('searching'))) {
      $('#input-problem').on('focus', function() {
        $('.conditions-live-search').addClass('searching');
        $('.conditionsArchive').addClass('searching');
      });
    }


  })(jQuery);

  //Smooth Scroll All Internal Links
  (function ($) {
      // Find all internal/null links and listen for clicks on them
      $('a[href^="#"]').not('.dropdown-toggle-anchor, .schedule-close, .schedule-button').on('click', function (event) {
        //Apply function only to internal links
        if ($(this).attr('href').length > 1) {
          //When internal links are clicked, scroll the window smoothly down (or up) to the elements they target.
          event.preventDefault();

          var cTarget = $(this).attr('href');


          $('html, body').animate({
            scrollTop : $(cTarget).offset().top - 210
          }, 1000);

          setTimeout(function () {
            window.location.hash = '#' + $(cTarget).attr('id');
          }, 100);

          return false;
        }
      });

      if ($('#input-problem').length > 0) {
          $('.fully-padded').attr('href','#0');
          setTimeout(function () {
           $('html, body').animate({
            scrollTop : $('#input-problem').offset().top - 210
            }, 1000);
          }, 1000);

          setTimeout(function () {
            $('#input-problem').focus();
           }, 1000);

          $('.fully-padded').on('click', function (event) {
            event.preventDefault();
            if($('.scrolled-past').length > 0) {
              $('html, body').animate({
              scrollTop : $('#input-problem').offset().top - 210
              }, 200);
            } else {
             $('html, body').animate({
              scrollTop : $('#input-problem').offset().top - 210
              }, 1000);
            }

            
          });

          $('#input-problem').on('focus', function () {
            var didScroll = false,
                bottom = ($('#input-problem').offset().top - $('#input-problem').height());

            $(window).scroll(function() {
              didScroll = true;
            });

            setInterval(function() {
              if ( didScroll ) {
                didScroll = false;
                if ($(window).scrollTop() >= (bottom - 20)) {
                  $('#input-problem').addClass('scrolled-past');
                  $('.searching').removeClass('searching');
                }
              }
            }, 250);
          });

            $('#input-problem').on('blur', function () {
              $('.contains').show();
            });

            $('.fully-padded').on('click', function (event) {
              // event.preventDefault();
              $('#input-problem').addClass('searching');
             $('html, body').animate({
              scrollTop : $('#input-problem').offset().top - 210
              }, 1);
              $('#input-problem').focus();
            });

          $.when($('.scrolled-past').length > 0).then(function() {
            $(window).on('scroll', function () {
                 var bottom = ($('#input-problem').offset().top   - $('#input-problem').height());
                if (($(window).scrollTop() > 0) && ($(window).scrollTop() < (bottom - 20))) {
                  $('#input-problem').focus();
                }
            });
          });
      }
  }(jQuery));

  if($('#problemsList').length > 0) {
    console.log($('#problemsList').css('display'));
    if($('#problemsList').css('display') === 'none') {
      $('#input-problem-label').css('display', 'flex');
    } else if ($('#problemsList').css('display') !== 'none') {
      $('#input-problem-label').css('display', 'none');
      $('#input-problem-label *').css('display', 'none');

      $('#input-problem-label').hide();
       $('#input-problem-label *').hide();
    }
  }

})(jQuery); // Fully reference jQuery after this point.
