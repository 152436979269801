// Controls the dropdown menu behavior

(function($) {

	$( '.dropdown-toggle-anchor' ).click( function() {
		// Dropdown menu slides down
		$( '.nav-primary' ).slideToggle();

		// Styling for the toggle icon background while menu is displayed
		$( '.dropdown-toggle-anchor' ).toggleClass( 'toggled-icon' );

		return false;
	});

	
	$(window).resize(function() {
		// If the window is resized after the menu has been hidden, remove the style that hides it
		var viewportWidth = $(window).width();
		if ( viewportWidth > 1230 && $( '.nav-primary' ).is( ':hidden' )) {
			$( '.nav-primary' ).removeAttr('style');
		}
	});

})(jQuery);