(function($) {

	$.fn.changeElementType = function(newType) {
	    var newElements = [];

	    $(this).each(function() {
	        var attrs = {};

	        $.each(this.attributes, function(idx, attr) {
	            attrs[attr.nodeName] = attr.nodeValue;
	        });

	        var newElement = $("<" + newType + "/>", attrs).append($(this).contents());

	        $(this).replaceWith(newElement);

	        newElements.push(newElement);
	    });

	    return $(newElements);
	};

	/**
	 * Desktop View for the Conditions Navigation on the Homepage
	 *
	 * (Divide menu into 3 lists)
	 */
	var total_li = $('.home-navigation ul li').length;
	var third_li = parseInt(total_li/3);
	var remainder_li = parseInt(total_li%3);
	$('.home-navigation ul:first-of-type').after('<ul></ul><ul></ul>');
	$('.home-navigation ul:nth-of-type(2)').append($('.home-navigation ul:first-of-type li').slice(third_li,((third_li*2)+remainder_li)));
	$('.home-navigation ul:nth-of-type(3)').append($('.home-navigation ul:first-of-type li').slice( parseInt($('.home-navigation ul:first-of-type li').length/2), (third_li*2) ));

	/**
     * Mobile View for the Conditions Navigation on the Homepage
     *
     * (Create a <select> out of the list of links)
	 */
	$('.home-navigation ul li').clone().appendTo( '.home-navigation select' );
	$('.home-navigation select li').changeElementType('option');
	$( '.home-navigation select option' ).each(function( index ) {
		$(this).attr('value', $(this).children('a').attr('href'));
	});
	$('.home-navigation select option a').contents().unwrap();
	$('.home-navigation select').on('change', function () {
          var url = $(this).val();
          if (url) {
              window.location = url;
          }
          return false;
      });

})(jQuery); // Fully reference jQuery after this point.